.flip-clock-wrapper {
  display: inline-block;
}
.flip-clock-wrapper > div {
  display: inline-block;
}
.flip-clock-wrapper ul {
  position: relative;
  padding: 0px;
  margin: 5px;
  width: 60px;
  height: 90px;
  font-size: 80px;
  font-weight: bold;
  line-height: 87px;
  border-radius: 6px;
  background: #000;
  display: inline-block;
  vertical-align: top;
  list-style: none;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
}
.flip-clock-wrapper ul li {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-decoration: none !important;
}
.flip-clock-wrapper ul li a {
  display: block;
  height: 100%;
  perspective: 200px;
  margin: 0 !important;
  overflow: visible !important;
  cursor: default !important;
}
.flip-clock-wrapper ul li a div {
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  height: 50%;
  font-size: 80px;
  overflow: hidden;
}
.flip-clock-wrapper ul li:first-child {
  z-index: 2;
}
.flip-clock-wrapper a {
  cursor: pointer;
  text-decoration: none;
  color: #ccc;
}
.flip-clock-wrapper a:hover {
  color: #fff;
}
.flip-clock-wrapper .flip {
  /*box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);*/
}
.flip-clock-wrapper .shadow {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.flip-clock-wrapper .up {
  transform-origin: 50% 100%;
  top: 0;
}
.flip-clock-wrapper .up .inn {
  top: 0;
  font-size: 85px;
  font-weight: 500;
}
.flip-clock-wrapper .up:after {
  content: "";
  position: absolute;
  top: 44px;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 3px;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.4);
}
.flip-clock-wrapper .down {
  transform-origin: 50% 0;
  bottom: 0;
}
.flip-clock-wrapper .down .inn {
  bottom: 0;
}
.flip-clock-wrapper .inn {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 200%;
  color: #ccc;
  /*text-shadow: 0 1px 2px #000;*/
  text-align: center;
  background-color: #502d2d;
  border-radius: 6px;
  font-size: 70px;
}
.flip-clock-wrapper .flip-clock-active {
  animation: asd 0.5s 0.5s linear both;
  z-index: 5;
  will-change: z-index; 
}
.flip-clock-wrapper .flip-clock-active .down {
  z-index: 2;
  animation: turn 0.5s 0.5s linear both;
  will-change: transform; 
}
.flip-clock-wrapper .flip-clock-active .down .shadow {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, black), color-stop(100%, rgba(0, 0, 0, 0.1)));
  background: linear, top, black 0%, rgba(0, 0, 0, 0.1) 100%;
  background: linear, to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%;
  animation: hide 0.5s 0.2s linear both;
  will-change: opacity; 
}
.flip-clock-wrapper .flip-clock-active .up .shadow {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, black));
  background: linear, top, rgba(0, 0, 0, 0.1) 0%, black 100%;
  background: linear, to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%;
  animation: hide 0.5s 0.3s linear both;
  will-change: opacity; 
}
.flip-clock-wrapper .flip-clock-before {
  z-index: 3 !important;
}
.flip-clock-wrapper .flip-clock-before .up {
  z-index: 2;
  animation: turn2 0.5s linear both;
  will-change: transform; 
}
.flip-clock-wrapper .flip-clock-before .up .shadow {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, black));
  background: linear, top, rgba(0, 0, 0, 0.1) 0%, black 100%;
  background: linear, to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%;
  animation: show 0.5s linear both;
  will-change: opacity; 
}
.flip-clock-wrapper .flip-clock-before .down .shadow {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, black), color-stop(100%, rgba(0, 0, 0, 0.1)));
  background: linear, top, black 0%, rgba(0, 0, 0, 0.1) 100%;
  background: linear, to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%;
  animation: show 0.5s linear both;
  will-change: opacity; 
}
@keyframes asd {
  0% {
    z-index: 2;
  }
  20% {
    z-index: 4;
  }
  100% {
    z-index: 4;
  }
}
@keyframes turn {
  0% {
    transform: rotate3d(1, 0, 0, 90deg);
  }
  100% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
}
@keyframes turn2 {
  0% {
    transform: rotate3d(1, 0, 0, 0deg);
  }
  100% {
    transform: rotate3d(1, 0, 0, -90deg);
  }
}
@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.flip-clock-wrapper .semicolon {
  display: inline-block;
  width: 10px;
  height: 100%;
  position: relative;
  top: 50px;
  transform: translate(0, -50%);
  vertical-align: top;
}
.flip-clock-wrapper .semicolon > span {
  display: block;
  width: 10px;
  height: 10px;
  background: #323434;
  border-radius: 50%;
  margin-bottom: 10px;
}
.flip-clock-wrapper .semicolon > span:last-child {
  margin-bottom: 0px;
}
.flip-clock-wrapper .title {
  font-size: 28px;
  font-weight: 500;
  text-align: center;
}

